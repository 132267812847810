/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/guide/browser-support
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** IE10 and IE11 requires the following for the Reflect API.
import 'core-js/es/reflect';
 */

/**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 */

// (window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
// (window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
// (window as any).__zone_symbol__BLACK_LISTED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames

/*
 * in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
 * with the following flag, it will bypass `zone.js` patch for IE/Edge
 */
//(window as any).__Zone_enable_cross_context_check = true;

/***************************************************************************************************
 * REDUX DEVTOOLS POLYFILLS
 *
 * Since core-js v3.0.0 the {Map, Set}#toJSON method has been removed.
 * Thus Maps/Sets will not show correctly in the Redux DevTools.
 * These polyfills fixes the issue.
 */
if (!ENVIRONMENT.prod) {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    (Map.prototype as any).toJSON = function () {
        return JSON.parse(JSON.stringify([...this]));
    };

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    (Set.prototype as any).toJSON = function () {
        return Array.from(this);
    };
}

import { ENVIRONMENT } from '@sbg-web/env';
/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js'; // Included with Angular CLI.

// Add global to window, assigning the value of window itself.
(window as any).global = window;

/***************************************************************************************************
 * APPLICATION IMPORTS
 */
