export class SystemKeys {
    static COUNTRY_CODES = {
        SOUTH_AFRICA: 'SBSA',
        PBBI: 'SBOG',
        NAMIBIA: 'SBNAM',
        UGANDA: 'SBUGA',
        BOTSWANA: 'SBBWA',
        GHANA: 'SBGHA',
        SWAZILAND: 'SBSWZ',
        TANZANIA: 'SBTZA',
        ZIMBABWE: 'SBZWE',
        ZAMBIA: 'SBZMB'
    };

    static SYSTEM_PRINCIPAL_KEYS = {
        SBSA: 'SBSA_BANKING',
        SBOG: 'BANKING',
        SBOG_GROUP: 'BANKING_GROUP',
        INSURANCE: 'SBIS',
        VAF: 'VAF',
        UCOUNT: 'LOYALTY',
        HOME_LOAN: 'HOME_LOAN'
    };
}
