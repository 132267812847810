import { ENVIRONMENT } from '../../index';

export class CountryCodesUtilities {
    public static isAllowedCountryCode(code: string): boolean {
        return ENVIRONMENT.allowCountries.map((country) => country.countryCode).includes(code);
    }

    public static isSupportedTerritoryKey(countryCode: string): boolean {
        const country = ENVIRONMENT.allowCountries.find((c) => c.countryCode === countryCode);
        return country?.isSupported ?? false;
    }
}
