import { environment } from './lib/environments/win-web/environment';

export { SystemKeys } from './lib/const/system-keys';
export { CountryCodesUtilities } from './lib/const/country-codes-utility';
export { MfeRouteModel } from './lib/interface/mfe-route.interface';

export const BASE_URL = environment.mobileApiPath;
export const AMS_GATEWAY_PATH = '/ams-gateway';
export const ENVIRONMENT = environment;
export const IS_LOCALHOST: boolean = location.host.indexOf('localhost') > -1;
export const LIVE_ENVIRONMENT: boolean = environment.prod || environment.staging || environment.uat || environment.sit;
