import { Environment } from '../../interface/environment.interface';
import { SystemKeys } from '../../const/system-keys';
import { project, scopes, firebaseConfig } from '../../project/project';

const pingScopes = { ...scopes, VAF: 'vaf.*.*', HOME_LOAN: 'sbg-homeloan.all' };

export const environment: Environment = {
    remoteDev: false,
    dev: false,
    uat: false,
    sit: true,
    staging: false,
    prod: false,
    e2e: false,
    native: false,
    secureFlag: false,
    buildNumber: project.buildNumber,
    mobileApiPath: project.mobileApiPath,
    version: project.version,
    manifest: 'module-federation.sit.manifest.json',
    channel: 'NOBI',
    allowPrincipalKeys: [
        SystemKeys.SYSTEM_PRINCIPAL_KEYS.SBSA,
        SystemKeys.SYSTEM_PRINCIPAL_KEYS.VAF,
        SystemKeys.SYSTEM_PRINCIPAL_KEYS.HOME_LOAN
    ],
    bankingPrincipalKeys: [SystemKeys.SYSTEM_PRINCIPAL_KEYS.SBSA],
    allowCountries: [
        { countryCode: SystemKeys.COUNTRY_CODES.PBBI, isSupported: false },
        { countryCode: SystemKeys.COUNTRY_CODES.SOUTH_AFRICA, isSupported: true }
    ],
    errorManagement: {
        showErrorReportDialog: false,
        enabled: false,
        useLogQueen: false,
        enableHttpErrorLogging: false,
        sampleRate: 0.9
    },
    tracing: {
        enabled: false,
        sampleRate: 0.9
    },
    pingInfo: {
        client_id: '340b10e7-144d-4f2d-8648-97f26818d4bb',
        hostURI: 'https://enterprisestssit.standardbank.co.za',
        scopes: pingScopes
    },
    trusteerId: '8116521',
    firebaseConfig: firebaseConfig,
    useProductionPinEncryptKey: false
};
